/* eslint-disable no-underscore-dangle */
import { FC, memo } from 'react';
import cn from 'clsx';
import Image from '@components/Image';
import { ButtonGroup } from '@framework/api/types';
import type { DatoProductImage } from '@framework/api/types';
import LinkGroup from '@components/LinkGroup';
import * as styles from './styles';

interface Props {
  threeCardGrid?: boolean;
  heading: string;
  description: string;
  image: DatoProductImage;
  backgroundImage: DatoProductImage;
  lightCard?: boolean;
  fullCard?: boolean;
  buttons?: ButtonGroup[];
}

const MarketingCard: FC<Props> = ({
  heading,
  image,
  description,
  buttons,
  lightCard,
  fullCard,
  backgroundImage,
  threeCardGrid,
}) => {
  const defaultCard = !threeCardGrid && !fullCard;
  const trueFull = fullCard && !threeCardGrid;
  const threeCard = threeCardGrid && !fullCard;

  return (
    <div
      style={{
        backgroundImage:
          backgroundImage && backgroundImage.responsiveImage.src
            ? `url(${backgroundImage.responsiveImage.src})`
            : null,
        backgroundPosition: fullCard && '70% 50%',
      }}
      className={cn(styles.marketingCardWrapper(fullCard, lightCard))}
      data-test-id="marketing-card-item"
    >
      <div
        className={cn(
          styles.marketingCardTextWrapper(
            defaultCard,
            trueFull,
            lightCard,
            threeCard
          )
        )}
      >
        <h3
          className={styles.marketingCardHeading}
          data-test-id="marketing-card-heading"
        >
          {heading}
        </h3>
        <p
          className={styles.description}
          data-test-id="marketing-card-description"
        >
          {description}
        </p>
        {Array.isArray(buttons) && buttons?.length > 0 && (
          <div className={styles.buttonStyles(threeCard)}>
            <LinkGroup links={buttons} isInverted={!lightCard} />
          </div>
        )}
      </div>
      {!fullCard && image && (
        <div className={styles.marketingCardImageWrapper}>
          <Image
            data={image.responsiveImage}
            width={image.responsiveImage.width}
            height={image.responsiveImage.height}
            dataTestID="marketing-card-image"
          />
        </div>
      )}
    </div>
  );
};

export default memo(MarketingCard);
