import { FC, useMemo, memo } from 'react';
import { DEFAULT_LANGUAGE_CODE, DEFAULT_LOCALE } from '@constants';
import { getRegion } from '@framework/api/utils/maxify';
import { useRouter } from 'next/router';
import parseLocale from '@utils/parse-locale';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import HydrateMdx from '@components/HydrateMdx';
import type {
  DatoProductImage,
  DatoAvailableLocales,
} from '@framework/api/types';
import Image from '@components/Image';
import extendedStyles from './extendedStyles.module.css';

import * as styles from './styles';

type Props = {
  heading: string;
  description?: string;
  image: DatoProductImage;
  markdownContent?: MDXRemoteSerializeResult;
  regions?: DatoAvailableLocales[];
};

const SimpleCard: FC<Props> = ({
  heading,
  description,
  image,
  markdownContent,
  regions,
}) => {
  const { locale, defaultLocale } = useRouter();
  const region = getRegion(locale);
  const parsedLocale = parseLocale(defaultLocale, locale);
  const lowercaseLocale = DEFAULT_LOCALE.toLowerCase();
  const lang =
    parsedLocale[0] === DEFAULT_LANGUAGE_CODE
      ? lowercaseLocale
      : parsedLocale[0];

  const regionalCard = useMemo(
    () =>
      Array.isArray(regions) ? regions.some(c => c.region === region) : null,
    [regions, region]
  );

  return regionalCard ? (
    <div className={styles.simpCardWrapper}>
      {image?.responsiveImage && (
        <div
          className={styles.simpCardImage}
          style={{ minHeight: '150px', maxHeight: '240px' }}
        >
          <Image
            data={image?.responsiveImage}
            width={image?.responsiveImage?.width}
            height={image?.responsiveImage?.height}
            className={styles.simpCardImage}
          />
        </div>
      )}
      {heading && (
        <h3 className={styles.simpCardHeading} lang={lang}>
          {heading}
        </h3>
      )}
      {markdownContent || description ? (
        <div className={extendedStyles.list}>
          {markdownContent ? <HydrateMdx mdx={markdownContent} /> : description}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default memo(SimpleCard);
