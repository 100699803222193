import { FC, memo, Fragment } from 'react';

import ContentWrapper from '@components/ContentWrapper';
import SectionWrapper from '@components/SectionWrapper';
import type { SimpleCardsProps } from '@framework/api/types';
import SimpleCard from './SimpleCard';

import * as styles from './styles';

type Props = {
  simpleCardGridData: SimpleCardsProps[];
  heading?: string;
  noContentWrapper?: boolean;
};

const SimpleCardGrid: FC<Props> = ({
  simpleCardGridData,
  heading,
  noContentWrapper,
}) => {
  const Wrapper = noContentWrapper ? Fragment : ContentWrapper;

  return Array.isArray(simpleCardGridData) && simpleCardGridData?.length > 0 ? (
    <SectionWrapper data-test-id="simple-card-block">
      <Wrapper>
        {heading && (
          <h2 className={styles.simpCardSectionHeading}>{heading}</h2>
        )}
        <div className={styles.simpCardSection}>
          {simpleCardGridData.map(card => (
            <SimpleCard
              key={card.id}
              heading={card.heading}
              description={card.description}
              image={card.image}
              markdownContent={card.markdownContent}
              regions={card.availableRegion}
            />
          ))}
        </div>
      </Wrapper>
    </SectionWrapper>
  ) : null;
};

export default memo(SimpleCardGrid);
