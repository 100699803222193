/* eslint-disable react/jsx-no-useless-fragment */
import { memo, FC } from 'react';
import cn from 'clsx';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import type {
  DatoProductImage,
  MarkdownIconBlocks,
} from '@framework/api/types';
import { useMedia } from '@utils/hooks';
import ContentWrapper from '@components/ContentWrapper';
import ButtonLink from '@components/ButtonLink';
import Image from '@components/Image';
import HydrateMdx from '@components/HydrateMdx';
import RenderImage from '@components/RenderImage';
import RiverIconBlock from './RiverIconBlock';
import * as styles from './styles';
import extendedStyles from './river.module.css';

export type Props = {
  backgroundColor?: string;
  heading: string;
  description?: string;
  markdownContent?: MDXRemoteSerializeResult;
  image?: DatoProductImage;
  logo?: DatoProductImage;
  buttonText?: string;
  buttonLink?: string;
  isEven: boolean;
  imageType?: string;
  icon?: string;
  markdownIconBlocks?: MarkdownIconBlocks[];
};

const COLOR_MAP = {
  transparent: 'bg-transparent',
  lightGray: 'bg-nzxt-light-grey-50',
};

const RiverItem: FC<Props> = ({
  backgroundColor,
  heading,
  description,
  markdownContent,
  buttonText,
  buttonLink,
  image,
  logo,
  isEven,
  imageType,
  markdownIconBlocks,
}) => {
  const hasContent = Boolean(markdownContent || description);
  const isBottomAligned = imageType && imageType === 'Bottom Aligned Image';
  const isFullBleed = imageType && imageType === 'Full Bleed Top-to-Bottom';
  const isSm = useMedia('sm');
  const bgColor = typeof backgroundColor === 'string' && backgroundColor;

  return (
    <section
      data-test-id="river-item-block"
      className={COLOR_MAP[bgColor] || COLOR_MAP.transparent}
    >
      <ContentWrapper
        className={cn(
          styles.outerSpacing(!isBottomAligned && !isFullBleed, isBottomAligned)
        )}
      >
        <div className={styles.getOuterWrapper(isEven)}>
          {image && (
            <div
              className={styles.getImageGridItem(isBottomAligned)}
              data-test-id="river-item-image"
            >
              {!isFullBleed ? (
                <Image
                  data={image.responsiveImage}
                  width={image.responsiveImage.width}
                  height={image.responsiveImage.height}
                  className={styles.image}
                  style={{
                    minHeight: '275px',
                    minWidth: '275px',
                  }}
                />
              ) : (
                <>
                  {isSm ? (
                    <Image
                      data={image.responsiveImage}
                      width={image.responsiveImage.width}
                      height={image.responsiveImage.height}
                      className={styles.image}
                      style={{
                        minHeight: '275px',
                        minWidth: '275px',
                      }}
                    />
                  ) : (
                    <figure
                      style={{
                        backgroundImage: `url(${image.responsiveImage.src})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'auto 100%',
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                      }}
                    />
                  )}
                </>
              )}
            </div>
          )}
          <div
            className={styles.getTextGridItem(isFullBleed)}
            data-test-id="river-item-text-grid"
          >
            <RenderImage
              image={logo}
              className={styles.logo}
              dataTestId="river-item-logo"
            />

            <h3 className={styles.heading} data-test-id="river-item-heading">
              {heading}
            </h3>
            {hasContent && (
              <div
                className={cn(styles.copy, extendedStyles.list)}
                data-test-id="river-item-description"
              >
                {markdownContent ? (
                  <HydrateMdx mdx={markdownContent} />
                ) : (
                  description
                )}
              </div>
            )}
            {markdownIconBlocks && (
              <div className={styles.markdownIconBlocksWrapper}>
                {markdownIconBlocks.map(item => (
                  <RiverIconBlock
                    heading={item.heading}
                    decoratedMarkdownContent={item.decoratedMarkdownContent}
                    iconName={item.iconName}
                    key={item.id}
                  />
                ))}
              </div>
            )}
            {buttonText && buttonLink && (
              <div
                className={styles.buttonWrapper}
                data-test-id="river-item-button"
              >
                <ButtonLink
                  asButton
                  href={buttonLink}
                  buttonStyle="secondary"
                  internalLink
                  customMargin
                >
                  {buttonText}
                </ButtonLink>
              </div>
            )}
          </div>
        </div>
      </ContentWrapper>
    </section>
  );
};

export default memo(RiverItem);
