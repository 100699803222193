import { classnames } from '@tailwindcss-classnames';

export const container = classnames(
  'bg-nzxt-light-grey-100',
  'rounded-lg',
  'flex',
  'justify-center'
);

export const navItems = classnames('flex', 'flex-wrap', 'content-around');

export const navItem = classnames(
  'w-1/2',
  'sm:w-1/3',
  'xl:w-40',
  'text-center',
  'p-5',
  'group'
);

export const heading = classnames(
  'text-sm',
  'font-semibold',
  'text-nzxt-light-grey-800'
);

export const image = classnames(
  'w-12',
  'm-auto',
  'mb-2',
  'group-hover:animate-bounceUp'
);

export const description = classnames('text-xs', 'text-nzxt-light-grey-800');
