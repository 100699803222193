/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import { memo, FC, Fragment } from 'react';
import Image from '@components/Image';
import type { DatoProductImage } from '@framework/api/types';
import ContentWrapper from '@components/ContentWrapper';
import SectionWrapper from '@components/SectionWrapper';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import ButtonLink from '@components/ButtonLink';
import RenderImage from '@components/RenderImage';
import * as styles from './styles';

export type Props = {
  heading: string;
  description?: string;
  image?: DatoProductImage;
  logo?: DatoProductImage;
  buttonText?: string;
  internalButtonLink?: {
    _modelApiKey: string;
    pageType?: string;
    slug: string;
    parentCategory?: {
      slug: string;
    };
  };
  externalButtonLink?: string;
  buttonHelperText?: string;
  url?: string;
  noContentWrapper?: boolean;
};

const HeroTall: FC<Props> = ({
  heading,
  description,
  buttonText,
  internalButtonLink,
  externalButtonLink,
  buttonHelperText,
  image,
  logo,
  noContentWrapper,
}) => {
  const Wrapper = noContentWrapper ? Fragment : ContentWrapper;

  const internalLink = internalButtonLink
    ? getCmsLinkUrl({
        contentType: internalButtonLink._modelApiKey,
        pageType: internalButtonLink.pageType,
        slug: internalButtonLink.slug,
        parentSlug: internalButtonLink?.parentCategory?.slug,
      })
    : null;

  const hasLink =
    (!!internalLink && !!buttonText) || (!!externalButtonLink && !!buttonText);

  return (
    <Wrapper data-test-id="hero-tall-block">
      <SectionWrapper>
        <div className={styles.OuterWrapper}>
          <div className={styles.TextGridItem}>
            <RenderImage
              image={logo}
              className={styles.logo}
              dataTestId="hero-tall-logo"
            />
            <h1 className="h1" data-test-id="hero-tall-heading">
              {heading}
            </h1>
            {description && (
              <p className={styles.copy} data-test-id="hero-tall-description">
                {description}
              </p>
            )}
            {hasLink && (
              <>
                <div className={styles.buttonWrapper}>
                  {internalLink ? (
                    <ButtonLink
                      asButton
                      buttonStyle="primary"
                      href={internalLink}
                      className={styles.extendedButtonStyles}
                      customMargin
                      internalLink
                      dataTestId="hero-tall-button-link"
                    >
                      {buttonText}
                    </ButtonLink>
                  ) : (
                    <>
                      {externalButtonLink && buttonText && (
                        <ButtonLink
                          asButton
                          buttonStyle="primary"
                          href={externalButtonLink}
                          className={styles.extendedButtonStyles}
                          customMargin
                          dataTestId="hero-tall-button-link"
                        >
                          {buttonText}
                        </ButtonLink>
                      )}
                    </>
                  )}
                </div>
                {buttonHelperText && (
                  <div
                    className={styles.buttonHelperText}
                    data-test-id="hero-tall-button-helper"
                  >
                    {buttonHelperText}
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.ImageGridItem}>
            <div className={styles.widthClamp}>
              {image && (
                <Image
                  data={image.responsiveImage}
                  loading="eager"
                  decoding="async"
                  dataTestID="hero-tall-image"
                />
              )}
            </div>
          </div>
        </div>
      </SectionWrapper>
    </Wrapper>
  );
};

export default memo(HeroTall);
