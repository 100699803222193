/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, InputHTMLAttributes, memo, forwardRef } from 'react';
import cn from 'clsx';
import * as styles from './styles';

type Classes = {
  inputClass?: string;
  labelClass?: string;
};

export interface ForwardedInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  classes?: Classes;
  labelFor: string;
  inputLabel: string;
  srOnlyLabel?: boolean;
  requiredError?: boolean;
  generalError?: string;
  patternError?: string;
  patternMessage?: string;
  customLabelStyling?: string;
  isInverted?: boolean;
}

const Input: FC<ForwardedInputProps> = forwardRef<
  HTMLInputElement,
  ForwardedInputProps
>(
  (
    {
      classes,
      labelFor,
      inputLabel,
      srOnlyLabel,
      requiredError,
      generalError,
      patternError,
      patternMessage,
      customLabelStyling,
      isInverted,
      ...rest
    },
    ref
  ) => {
    const rootClassName = cn(
      styles.input(srOnlyLabel, isInverted),
      {},
      classes?.inputClass
    );
    const labelClassName = cn(
      styles.inputLabel(isInverted),
      {},
      classes?.labelClass
    );

    return (
      <label
        htmlFor={labelFor}
        className={customLabelStyling || labelClassName}
      >
        <span className={srOnlyLabel ? 'sr-only' : null}>{inputLabel}</span>
        <input
          id={labelFor}
          className={rootClassName}
          ref={ref}
          {...rest}
          required={requiredError}
        />
        {patternMessage && !generalError && !patternError && !requiredError && (
          <p className={styles.patternMessage}>{patternMessage}</p>
        )}
        {(generalError || patternError) && (
          <p className={styles.errorText}>{generalError || patternError}</p>
        )}
      </label>
    );
  }
);

export default memo(Input);
