import { classnames } from '@tailwindcss-classnames';

const srOnly = 'mt-2.5';
const darkInput = classnames(
  'bg-nzxt-dark-grey-700',
  'border-nzxt-dark-grey-700'
);
export const input = (isSrOnly: boolean, isInverted: boolean): string =>
  classnames(
    { [srOnly]: !isSrOnly, [darkInput]: isInverted },
    'focus:ring',
    'outline-none',
    'focus:outline-none',
    'focus:shadow-none',
    'focus:ring-nzxt-volt-400',
    'focus:ring-1',
    'focus:border-nzxt-volt-400',
    'appearance-none',
    'block',
    'w-full',
    'px-3',
    'py-2',
    'border',
    'border-gray-300',
    'rounded-md',
    'shadow-sm',
    'placeholder-gray-400',
    'sm:text-sm'
  );

const textWhite = classnames('text-white');
const textGray700 = classnames('text-gray-700');
export const inputLabel = (isInverted?: boolean): string =>
  classnames('block', 'text-sm', 'font-medium', 'focus:outline-none', {
    [textWhite]: isInverted,
    [textGray700]: !isInverted,
  });

export const errorText = classnames('text-red-500', 'mt-2', 'text-xs');
export const patternMessage = classnames('mt-2', 'text-xs');
