/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import { ButtonGroup } from '@framework/api/types';
import type { DatoProductImage } from '@framework/api/types';
import ContentWrapper from '@components/ContentWrapper';
import MarketingCard from './MarketingCard';
import * as styles from './styles';

export type MarketingCardProps = {
  heading: string;
  description?: string;
  image?: DatoProductImage;
  backgroundImage?: DatoProductImage;
  lightCard?: boolean;
  fullCard?: boolean;
  buttons?: ButtonGroup[];
  id?: string;
};

export interface Props {
  marketingCardData: MarketingCardProps[];
  threeCardGrid?: boolean;
}

const MarketingCardSection: FC<Props> = ({
  threeCardGrid,
  marketingCardData,
}) => {
  const hasMarketingCards =
    Array.isArray(marketingCardData) &&
    marketingCardData?.filter(card => card?.id);

  return hasMarketingCards ? (
    <ContentWrapper
      className={styles.marketingCardSectionWrapper(threeCardGrid)}
    >
      {marketingCardData.map(item => (
        <MarketingCard
          key={item?.id}
          threeCardGrid={threeCardGrid}
          heading={item?.heading}
          image={item?.image}
          backgroundImage={item?.backgroundImage}
          description={item?.description}
          lightCard={item?.lightCard}
          fullCard={item?.fullCard}
          buttons={item?.buttons}
        />
      ))}
    </ContentWrapper>
  ) : null;
};

export default memo(MarketingCardSection);
