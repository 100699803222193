import { classnames } from '@tailwindcss-classnames';

export const gridItem = classnames(
  'col-span-12',
  'flex',
  'flex-col',
  'justify-start'
);

export const logo = classnames(
  'w-20',
  'xl:w-24',
  'mx-auto',
  'lg:mx-0',
  'mb-4',
  'lg:mb-2',
  'lg:mx-0'
);

export const copy = classnames(
  'mt-3',
  'md:text-lg',
  'lg:text-xl',
  'mb-8',
  'text-nzxt-light-grey-800'
);

export const buttonWrapper = classnames(
  'flex',
  'flex-col',
  'lg:flex-row',
  'm-auto',
  'mb-8',
  'lg:m-0'
);
export const extendedButtonStyles = classnames(
  'max-w-max',
  'mx-auto',
  'md:ml-0'
);
export const extendedTextButtonStyles = classnames(
  'text-nzxt-dark-grey-500',
  'hover:text-nzxt-dark-grey-600'
);

export const buttonHelperText = classnames(
  'text-nzxt-light-grey-600',
  'mt-3',
  'ml-3',
  'text-xs'
);

export const ImageGridItem = classnames(
  'lg:order-2',
  'lg:col-span-7',
  gridItem,
  'mb-4',
  'sm:mb-0',
  'lg:col-start-7'
);

export const widthClamp = classnames(
  'max-w-lg',
  'md:max-w-lg',
  'mx-auto',
  'lg:mx-0',
  'lg:max-w-full'
);

export const TextGridItem = classnames(gridItem, 'lg:col-span-5');

export const OuterWrapper = classnames(
  'mx-auto',
  'grid',
  'grid-cols-12',
  'gap-4',
  'lg:gap-6',
  'text-center',
  'lg:text-left'
);
