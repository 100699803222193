import { classnames } from '@tailwindcss-classnames';

const evenGrid = classnames('justify-center', 'md:flex-row');
const unevenGrid = classnames('justify-center', 'md:flex-row-reverse');
export const getOuterWrapper = (isEven: boolean): string =>
  classnames(
    {
      [evenGrid]: !isEven,
      [unevenGrid]: isEven,
    },
    'mx-auto',
    'flex',
    'flex-col'
  );

const defaultSpacing = classnames('py-6', 'md:py-12', 'xl:py-16');
const topSpacing = classnames('pt-6', 'md:pt-12', 'xl:pt-16');

export const outerSpacing = (
  isDefault: boolean,
  isBottomAligned: boolean
): string =>
  classnames('py-3', 'md:py-0', 'relative', 'overflow-hidden', {
    [defaultSpacing]: isDefault,
    [topSpacing]: isBottomAligned,
  });

export const gridItem = classnames(
  'flex',
  'flex-col',
  'mx-auto',
  'md:mx-10',
  'w-full',
  'md:w-1/2'
);

const defaultVerticalAlignement = classnames('justify-center');
const bottomVerticalAlignement = classnames('justify-end');

export const getImageGridItem = (isBottomAligned: boolean): string =>
  classnames(gridItem, 'mb-4', 'md:mb-0', 'max-w-sm', 'md:max-w-md', {
    [defaultVerticalAlignement]: !isBottomAligned,
    [bottomVerticalAlignement]: isBottomAligned,
  });

const fullBleedSpacing = classnames('md:py-16', 'lg:py-40', 'md:pl-10');

export const getTextGridItem = (isFullBleed: boolean): string =>
  classnames(gridItem, 'py-3', 'max-w-lg', 'justify-center', {
    [fullBleedSpacing]: isFullBleed,
  });

export const image = classnames('flex-shrink-0');

export const heading = classnames(
  'font-bold',
  'lg:text-4xl',
  'text-3xl',
  'bg-gradient-to-t',
  'mb-3',
  'text-nzxt-light-grey-800'
);

export const logo = classnames('w-1/2', 'mb-4');

export const copy = classnames(
  'mt-1',
  'text-base',
  'leading-7',
  'text-nzxt-light-grey-800'
);

export const buttonWrapper = classnames(
  'mt-4',
  'flex',
  'flex-col',
  'lg:flex-row'
);
export const extendedTextButtonStyles = classnames(
  'text-nzxt-dark-grey-500',
  'hover:text-nzxt-dark-grey-600'
);

export const iconStyles = classnames(
  'w-14',
  'h-14',
  'text-nzxt-dark-grey-500',
  'fill-current',
  'mb-2',
  'md:mb-4'
);

export const markdownIconBlocksWrapper = classnames(
  'mt-4',
  'mb-4',
  'space-y-8'
);
export const iconBlockwrapper = classnames('flex');
export const iconWrapper = classnames('mr-8', 'flex-shrink-0');
export const icon = classnames('h-14', 'w-14');
export const iconBlockHeading = classnames(
  'text-base',
  'xl:text-lg',
  'font-semibold'
);
export const iconBlockMDXWrapper = classnames(
  'mt-1',
  'text-gray-500',
  'text-base'
);
