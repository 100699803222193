/* eslint-disable no-underscore-dangle */
import { memo, FC } from 'react';
import Image from '@components/Image';
import type { DatoProductImage } from '@framework/api/types';
import ContentWrapper from '@components/ContentWrapper';
import SectionWrapper from '@components/SectionWrapper';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import ButtonLink from '@components/ButtonLink';
import * as styles from './styles';

export type Props = {
  heading?: string;
  description?: string;
  image?: DatoProductImage;
  logo?: DatoProductImage;
  buttonText?: string;
  buttonLink?: string;
  buttonTextSecondary?: string;
  buttonLinkSecondary?: string;
  internalButtonLinkPrimary?: {
    _modelApiKey: string;
    pageType?: string;
    slug: string;
    parentCategory?: {
      slug: string;
    };
  };
  internalButtonLinkSecondary?: {
    _modelApiKey: string;
    pageType?: string;
    slug: string;
    parentCategory?: {
      slug: string;
    };
  };
  url?: string;
};

const Hero: FC<Props> = ({
  heading,
  description,
  buttonText,
  buttonTextSecondary,
  internalButtonLinkPrimary,
  internalButtonLinkSecondary,
  image,
}) => {
  const internalLinkPrimary = internalButtonLinkPrimary
    ? getCmsLinkUrl({
        contentType: internalButtonLinkPrimary._modelApiKey,
        pageType: internalButtonLinkPrimary.pageType,
        slug: internalButtonLinkPrimary.slug,
        parentSlug: internalButtonLinkPrimary?.parentCategory?.slug,
      })
    : null;

  const internalLinkSecondary = internalButtonLinkSecondary
    ? getCmsLinkUrl({
        contentType: internalButtonLinkSecondary._modelApiKey,
        pageType: internalButtonLinkSecondary.pageType,
        slug: internalButtonLinkSecondary.slug,
        parentSlug: internalButtonLinkSecondary?.parentCategory?.slug,
      })
    : null;

  return (
    <ContentWrapper data-test-id="hero-block">
      <SectionWrapper>
        <div className={styles.OuterWrapper}>
          <div className={styles.TextGridItem}>
            {heading && (
              <h1 data-test-id="hero-heading" className={styles.heading}>
                {heading}
              </h1>
            )}
            {description && (
              <p className={styles.copy} data-test-id="hero-description">
                {description}
              </p>
            )}
            {buttonText && internalLinkPrimary && (
              <div className={styles.buttonWrapper}>
                <ButtonLink
                  asButton
                  buttonStyle="primary"
                  internalLink
                  href={internalLinkPrimary}
                  customMargin
                  dataTestId="hero-first-button-text"
                >
                  {buttonText}
                </ButtonLink>
                {buttonTextSecondary && internalLinkSecondary && (
                  // TODO: Killthepill button styles
                  <ButtonLink
                    asButton
                    buttonStyle="primary"
                    internalLink
                    href={internalLinkSecondary}
                    customMargin
                    dataTestId="hero-second-button-text"
                  >
                    {buttonTextSecondary}
                  </ButtonLink>
                )}
              </div>
            )}
          </div>
          <div className={styles.ImageGridItem}>
            <div className={styles.widthClamp}>
              {image && (
                <Image
                  data={image.responsiveImage}
                  loading="eager"
                  decoding="async"
                  dataTestID="hero-image"
                />
              )}
            </div>
          </div>
        </div>
      </SectionWrapper>
    </ContentWrapper>
  );
};

export default memo(Hero);
