import { classnames } from '@tailwindcss-classnames';

export const simpCardWrapper = classnames(
  'pt-6',
  'border-t',
  'text-nzxt-light-grey-800'
);
export const simpCardImage = classnames(
  'rounded-lg',
  'w-full',
  'mx-auto',
  'overflow-hidden'
);
export const simpCardHeading = classnames('h6', 'py-2', 'hyphens-auto');
export const simpCardSection = classnames(
  'grid',
  'grid-cols-1',
  'md:grid-cols-2',
  'xl:grid-cols-3',
  'gap-8'
);
export const simpCardSectionHeading = classnames('h3', 'pb-8');
