/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import ContentWrapper from '@components/ContentWrapper';
import SectionWrapper from '@components/SectionWrapper';
import Link from 'next/link';
import Image from '@components/Image';
import type { DatoProductImage, LinkedItem } from '@framework/api/types';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import * as styles from './styles';

export type IconNavItem = {
  id: string;
  heading: string;
  description: string;
  image: DatoProductImage;
  internalButtonLink: LinkedItem;
};

export type Props = {
  iconNavItems?: IconNavItem[];
};

const IconNav: FC<Props> = ({ iconNavItems }) => (
  <ContentWrapper data-test-id="icon-nav-collection-block">
    <SectionWrapper>
      <div className={styles.container}>
        <div>
          <ul className={styles.navItems}>
            {iconNavItems.map(item => (
              <li
                key={`icon-nav-item-${item.id}`}
                className={styles.navItem}
                data-test-id="icon-nav-item-block"
              >
                <Link
                  href={getCmsLinkUrl({
                    contentType: item.internalButtonLink?._modelApiKey,
                    pageType: item.internalButtonLink?.pageType,
                    slug: item.internalButtonLink?.slug,
                    parentSlug: item.internalButtonLink?.parentCategory?.slug,
                  })}
                  passHref
                  data-test-id="icon-nav-item-internal-link"
                >
                  {item.image && (
                    <Image
                      data={item.image.responsiveImage}
                      width={item.image.responsiveImage.width}
                      height={item.image.responsiveImage.height}
                      className={styles.image}
                      dataTestID="icon-nav-item-image"
                    />
                  )}
                  {item.heading && (
                    <h3
                      className={styles.heading}
                      data-test-id="icon-nav-item-heading"
                    >
                      {item.heading}
                    </h3>
                  )}
                  {item.description && (
                    <p
                      className={styles.description}
                      data-test-id="icon-nav-item-description"
                    >
                      {item.description}
                    </p>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </SectionWrapper>
  </ContentWrapper>
);

export default memo(IconNav);
