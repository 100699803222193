/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import HydrateMdx from '@components/HydrateMdx';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import IconDynamic from '@components/IconDynamic';
import * as styles from './styles';

type Props = {
  heading?: string;
  iconName?: string;
  decoratedMarkdownContent?: MDXRemoteSerializeResult;
};

const RiverIconBlock: FC<Props> = ({
  heading,
  iconName,
  decoratedMarkdownContent,
}) => (
  <div className={styles.iconBlockwrapper} data-test-id="river-item-icon-block">
    {iconName && (
      <div className={styles.iconWrapper}>
        <IconDynamic iconName={iconName} className={styles.icon} />
      </div>
    )}
    <div>
      {heading && (
        <h4
          className={styles.iconBlockHeading}
          data-test-id="river-item-icon-block-heading"
        >
          {heading}
        </h4>
      )}
      {decoratedMarkdownContent && (
        <div
          className={styles.iconBlockMDXWrapper}
          data-test-id="river-item-markdown-block-content"
        >
          <HydrateMdx mdx={decoratedMarkdownContent} />
        </div>
      )}
    </div>
  </div>
);
export default memo(RiverIconBlock);
