/* eslint-disable no-underscore-dangle */
import { FC, memo } from 'react';
import cn from 'clsx';
import type { DatoProductImage } from '@framework/api/types';
import GTM from '@utils/gtm';
import proxyImage from '@utils/proxy-image';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import ButtonLink from '@components/ButtonLink';
import Image from '@components/Image';
import SectionWrapper from '@components/SectionWrapper';
import * as styles from './styles';
import cssStyles from './CrftFeature.module.css';

export type Props = {
  heading: string;
  title?: string;
  subheading?: string;
  description?: string;
  buttonText?: string;
  internalButtonLink?: {
    _modelApiKey: string;
    pageType?: string;
    slug: string;
    parentCategory?: {
      slug: string;
    };
  };
  backgroundImage?: DatoProductImage;
  textBackgroundImage?: DatoProductImage;
  featuredCaseImage: DatoProductImage;
  logos?: DatoProductImage[];
};

const CrftFeature: FC<Props> = ({
  heading,
  title,
  subheading,
  description,
  buttonText,
  internalButtonLink,
  backgroundImage,
  textBackgroundImage,
  featuredCaseImage,
  logos,
}) => {
  const proxyBackgroundImage = backgroundImage
    ? proxyImage(backgroundImage.responsiveImage)
    : null;

  const proxyTextBackgroundImage = textBackgroundImage
    ? proxyImage(textBackgroundImage.responsiveImage)
    : null;

  const internalLink = internalButtonLink
    ? getCmsLinkUrl({
        contentType: internalButtonLink._modelApiKey,
        pageType: internalButtonLink.pageType,
        slug: internalButtonLink.slug,
        parentSlug: internalButtonLink?.parentCategory?.slug,
      })
    : null;

  const logosLeft = logos?.slice(0, 3);
  const logosRight = logos?.slice(3, 7);

  // track link click via GA
  const handleLinkClick = (): void => {
    const event = 'heroSectButtonClick';

    const payload = {
      heroSectLinkUrl: internalLink,
      heroSectImgUrl: proxyBackgroundImage ? proxyBackgroundImage.src : null,
    };

    GTM.dataLayer({
      dataLayer: {
        event,
        ...payload,
      },
    });
  };

  return (
    <SectionWrapper data-test-id="crft-hero-block">
      <div className={styles.crftContainer}>
        <div className={styles.headingContainer}>
          {title && (
            <p
              className={cn(cssStyles.clipText, styles.clippedText)}
              style={{
                backgroundImage:
                  proxyTextBackgroundImage && proxyTextBackgroundImage.src
                    ? `url(${proxyTextBackgroundImage.src})`
                    : null,
              }}
              data-test-id="crft-title"
            >
              {title}
            </p>
          )}
          <p
            className={cn(cssStyles.clipTextTwo, styles.clippedText)}
            style={{
              backgroundImage:
                proxyTextBackgroundImage && proxyTextBackgroundImage.src
                  ? `url(${proxyTextBackgroundImage.src})`
                  : null,
            }}
          >
            CRFT
          </p>
        </div>
        <div
          className={cn(styles.bgContainer, cssStyles.bgCon)}
          style={{
            backgroundImage:
              proxyBackgroundImage && proxyBackgroundImage.src
                ? `url(${proxyBackgroundImage.src})`
                : null,
          }}
          data-test-id="crft-background-image"
        >
          <div className={styles.ctaContainer}>
            {heading && (
              <p className={styles.ctaHeading} data-test-id="crft-heading">
                {heading}
              </p>
            )}
            {subheading && (
              <p
                data-test-id="crft-subheading"
                className={styles.ctaSubheading}
              >
                {subheading}
              </p>
            )}
            {description && (
              <p data-test-id="crft-description" className={styles.ctaText}>
                {description}
              </p>
            )}
            <div className={styles.ctaButtonWrapper}>
              {buttonText && internalLink && (
                <ButtonLink
                  asButton
                  href={internalLink}
                  internalLink={Boolean(internalLink)}
                  buttonStyle="primary"
                  customMargin
                  onClick={() => handleLinkClick()}
                  onKeyDown={() => handleLinkClick()}
                  tabIndex={0}
                  role="link"
                  dataTestId="crft-button-link"
                >
                  {buttonText}
                </ButtonLink>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={cn(styles.bottomGrid, cssStyles.gridNegativeSpace)}>
        {featuredCaseImage && (
          <div
            className={styles.featuredCase}
            data-test-id="crft-feature-case-image-block"
          >
            <Image
              dataTestID="crft-hero-image"
              data={featuredCaseImage.responsiveImage}
              decoding="async"
            />
          </div>
        )}
        <div className={styles.logosLeft}>
          <div className={styles.logosContainer} data-test-id="crft-left-logo">
            {logosLeft &&
              logosLeft.map(item => (
                <Image
                  key={item.id}
                  data={item.responsiveImage}
                  decoding="async"
                />
              ))}
          </div>
        </div>
        <div className={styles.logosRight}>
          <div className={styles.logosContainer} data-test-id="crft-right-logo">
            {logosRight &&
              logosRight.map(item => (
                <Image
                  key={item.id}
                  data={item.responsiveImage}
                  decoding="async"
                />
              ))}
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default memo(CrftFeature);
