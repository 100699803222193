import { classnames } from '@tailwindcss-classnames';

const fullCard = classnames(
  'col-span-2',
  'bg-no-repeat',
  'px-4',
  'py-12',
  'lg:py-32',
  'lg:px-12',
  'text-white',
  'bg-cover'
);
const defaultCard = classnames('pt-10', 'px-6', 'col-span-2', 'md:col-span-1');

const defaultBg = classnames(
  'bg-gradient-to-b',
  'from-nzxt-dark-grey-600',
  'via-nzxt-dark-grey-700',
  'to-nzxt-dark-grey-900'
);

const lightCard = classnames(
  'bg-gradient-to-b',
  'from-nzxt-light-grey-50',
  'to-nzxt-light-grey-100'
);

export const marketingCardWrapper = (
  isFullCard: boolean,
  isLightCard: boolean
): string =>
  classnames('rounded-lg', 'flex', 'flex-col', 'transition', {
    [defaultCard]: !isFullCard,
    [defaultBg]: !isLightCard,
    [fullCard]: isFullCard,
    [lightCard]: isLightCard,
  });

const lightCardText = classnames('text-nzxt-light-grey-800');
const darkCardText = classnames('text-white');

const bastText = classnames('text-sm', 'md:text-base');

const defaultText = classnames(
  'text-center',
  'md:pb-6',
  'lg:pb-12',
  'mx-auto',
  'lg:px-4',
  'items-center',
  'xl:w-4/5',
  'lg:w-full',
  'flex-1',
  'mb-12',
  'md:mb-0',
  'sm:w-4/5'
);

const fullCardText = classnames('w-2/3', 'md:w-1/2', 'lg:w-1/3');

const threeCardText = classnames('w-4/5', 'h-full', 'pb-10');

export const marketingCardContentWrapper = classnames(
  'flex',
  'flex-col',
  'min-h-full',
  'justify-end',
  'flex-1'
);

export const marketingCardTextWrapper = (
  isDefault: boolean,
  isFullCard: boolean,
  isLightCard: boolean,
  isThreeCard: boolean
): string =>
  classnames('flex', 'flex-col', bastText, {
    [defaultText]: isDefault,
    [fullCardText]: isFullCard,
    [threeCardText]: isThreeCard,
    [lightCardText]: isLightCard,
    [darkCardText]: !isLightCard,
  });

export const marketingCardHeading = classnames(
  'text-xl',
  'md:text-2xl',
  'font-semibold',
  'mb-3'
);

export const description = classnames('mb-3');
export const marketingCardImageWrapper = classnames(
  'w-4/5',
  'mx-auto',
  'flex',
  'justify-center'
);

const alignToBottom = classnames('mt-auto');
const defaultSpacing = classnames('mt-4');
export const buttonStyles = (isThreeCard: boolean): string =>
  classnames('max-w-max', {
    [defaultSpacing]: !isThreeCard,
    [alignToBottom]: isThreeCard,
  });

const defaultGrid = classnames('grid-cols-2');
const threeCardGrid = classnames('grid-cols-1', 'lg:grid-cols-3');
export const marketingCardSectionWrapper = (isThreeBy: boolean): string =>
  classnames('grid', 'gap-4', {
    [defaultGrid]: !isThreeBy,
    [threeCardGrid]: isThreeBy,
  });
