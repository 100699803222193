import { classnames } from '@tailwindcss-classnames';

export const clippedText = classnames(
  'font-bold',
  'leading-none',
  'relative',
  'inline-block',
  'bg-clip-text',
  'text-transparent',
  'bg-cover',
  'bg-center',
  'uppercase'
);

export const crftContainer = classnames(
  'bg-top',
  'text-center',
  'relative',
  'w-full',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'mx-auto',
  'md:px-6',
  'lg:px-12'
);

export const headingContainer = classnames(
  'text-center',
  'flex',
  'flex-col',
  'relative',
  'z-10'
);
export const bgContainer = classnames('pt-20', 'lg:pt-24', 'xl:pt-28');
export const ctaContainer = classnames('text-white', 'max-w-prose', 'm-auto');
export const ctaHeading = classnames('text-xl', 'md:text-5xl', 'font-bold');
export const ctaSubheading = classnames('font-semibold', 'md:text-lg', 'mb-6');
export const ctaText = classnames('mb-4', 'text-sm', 'md:text-base', 'px-4');
export const ctaButtonWrapper = classnames('max-w-max', 'mx-auto');
export const bottomGrid = classnames(
  'lg:grid',
  'grid-cols-12',
  'gap-2',
  'w-full',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'mx-auto',
  'md:px-6',
  'lg:px-12',
  'relative',
  'z-30'
);
export const bottomGridItem = classnames('col-span-4');
export const featuredCase = classnames(
  bottomGridItem,
  'sm:order-2',
  'w-72',
  'md:w-80',
  'lg:w-auto',
  'm-auto',
  'lg:-mb-11'
);
export const logosLeft = classnames(bottomGridItem, 'sm:order-1');
export const logosRight = classnames(bottomGridItem, 'sm:order-3');
export const logosContainer = classnames(
  'flex',
  'space-x-2',
  'items-end',
  'h-full'
);

export const featuredItem = classnames('relative');
export const image = classnames('absolute');
