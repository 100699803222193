import { classnames } from '@tailwindcss-classnames';

export const OuterWrapper = classnames(
  'mx-auto',
  'grid',
  'grid-cols-12',
  'gap-4',
  'lg:gap-6',
  'text-center',
  'lg:text-left',
  'bg-nzxt-light-grey-100',
  'p-6',
  'overflow-hidden'
);

export const gridItem = classnames(
  'col-span-12',
  'flex',
  'flex-col',
  'justify-center'
);

export const buttonWrapper = classnames(
  'flex',
  'flex-col',
  'lg:flex-row',
  'm-auto',
  'mb-8',
  'lg:m-0',
  'lg:space-x-4',
  'space-y-4',
  'lg:space-y-0',
  'w-full',
  'sm:w-1/2',
  'lg:w-full'
);

export const heading = classnames(
  'text-gradient',
  'bg-gradient-to-t',
  'to-nzxt-light-grey-500',
  'from-nzxt-light-grey-800',
  'h1'
);

export const extendedTextButtonStyles = classnames(
  'text-nzxt-dark-grey-500',
  'hover:text-nzxt-dark-grey-600',
  'max-w-max'
);

export const TextGridItem = classnames(
  gridItem,
  'col-start-1',
  'lg:col-start-2',
  'lg:col-span-6'
);

export const copy = classnames(
  'mt-3',
  'lg:text-lg',
  'mb-8',
  'text-nzxt-light-grey-800'
);

export const ImageGridItem = classnames(
  'lg:order-2',
  'lg:col-span-5',
  gridItem,
  'mb-4',
  'sm:mb-0'
);

export const widthClamp = classnames(
  'max-w-lg',
  'md:max-w-lg',
  'mx-auto',
  'lg:mx-0',
  'lg:max-w-full'
);
